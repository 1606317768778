import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap'
import YoutubeModal from '../shared/YoutubeModal'
import {graphql, StaticQuery} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import playIcon from 'images/icons/btn-play.svg'
import styled from "styled-components";
import TVAnimation from "../Animations/TVAnimation";

const Content = styled.div`
    text-align: center;
    background: black;
    
    h2, p {
        color: white;
    }
`

class VideoBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            videoId: '',
            playing: false,
            stopped: false
        }

    }

    toggleModal = (e, videoId) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen,
            videoId
        });
    };

    render() {
        const {modalOpen, videoId} = this.state;
        const showVideos = this.props.data.allVideosCastAlbumJson.edges.map(({node}) => {
            return (
                <Col xs={12} sm={6} md={4} className="video mb-lg-5" key={node.id}>
                    <div className="videoImg-wrapper" tabIndex={0} role="button"
                         onKeyDown={(e) => this.toggleModal(e, node.videoId)}
                         onClick={(e) => this.toggleModal(e, node.videoId)}>
                        <img src={`https://img.youtube.com/vi/${node.videoId}/hqdefault.jpg`} alt={node.name}
                             title={node.name}
                             className="img-fluid"
                        />
                        <div className="play-icon">
                            <img src={playIcon} alt="" title=""/>
                        </div>
                    </div>
                    <p className="font-italic pt-3" style={{ fontSize: "1.1rem"}}>
                        <strong className="text-uppercase">{node.title}</strong>
                    </p>
                </Col>
            )
        })

        return (
            <Content>

                <Container>
                    <Row className="justify-content-center">
                        {showVideos}
                    </Row>
                </Container>
                <YoutubeModal isOpen={modalOpen} toggleModal={this.toggleModal} videoId={videoId}/>
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
               allVideosCastAlbumJson {
                    edges {
                        node {
                            id
                            title
                            videoId
                        }
                    }
                }
            }`
        }
        render={data => (
            <VideoBlock {...props} data={data}/>
        )}
    />
)