import React, {Component} from "react"
import styled from "styled-components"
import {Container, Row, Col} from "reactstrap"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import StyledButton from "components/shared/StyledButton"
import {media} from "utils/Media"

const BgWrapper = styled.div`
    background-color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    h4 {
        text-align: center;
        font-size: ${props => props.theme.font.size.xl};
        color: ${props => props.theme.colors.white};
        font-family: ${props => props.theme.font.family.black};
        text-transform: none;
        font-weight: 900;
    }

    p {
        color: ${props => props.theme.colors.white};
    }
    
    &.inverse {
        background-color: ${props => props.theme.colors.white};
        
        h4, p {
            color: ${props => props.theme.colors.black};
        } 
    }  
`

const FormGroup = styled.div`
    display: inline-block;

    @media ${media.md} {
        border: 1px solid ${props => props.theme.colors.red}; 
    }
    
    .hidden {
        display: none;
    }
`

const StyledInput = styled.input`
    background: transparent;
    padding: .5rem;
    width: 100%;
    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.red}; 
    margin-bottom: .5rem;
    border-radius: 0;

    @media ${media.md} {
        border: 0;
        padding: 0 .75rem;
        width: auto;
        min-width: 250px;
        margin-bottom: 0;
        height: 100%;
    }
    
    &.inverse {
        color: ${props => props.theme.colors.black};
        
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: ${props => props.theme.colors.black};
          opacity: 1; /* Firefox */
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: ${props => props.theme.colors.black};
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: ${props => props.theme.colors.black};
        }
    }

    &::placeholder {
        color: ${props => props.theme.colors.white};
        font-size: ${props => props.theme.font.size.sm};
    }
`

const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.red}; 
    padding: .5rem 0;
    width: 100%;
    text-align: center;
`

const StyledButtonStyled = styled(StyledButton)`
    width: 270px;
    margin: 0 auto;
    display: block;

    @media ${media.md} {
        display: inline-block;
        width: auto;
    }
`

class SignUp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailAddress: '',
            castAlbum: "",
            access: ""
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Update value in state
        this.setState({
            [name]: value,
            castAlbum: this.props.$inverse ? "Yes" : "No",
            access: this.props.$access ? "Yes" : "No", //TODO
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        return {
            EMAIL: this.state.emailAddress,
            CASTALBUM: this.state.castAlbum,
            ACCESS: this.state.access
        }
    }
    onSiteChanged = (e) => {
        console.log(e.currentTarget.value)
    }

    formatMessage = (message) => {
        if (message.includes("0 - Please enter a value")) {
            return "Please enter your email address."
        }

        if (message.includes("An email address must contain")) {
            return "Please enter a valid email address."
        }

        if (message.includes("The domain portion of the email address is invalid")) {
            return "Please enter a valid email address."
        }

        if (message.includes("This email cannot be added to this list")) {
            return "This email address is already registered. Please enter a different email address."
        }

        return message
    }

    render() {

        const url = "https://dewynters.us1.list-manage.com/subscribe/post?u=2f36c1eba84f0edbfa535ad91&amp;id=6fc96b8eac";

        const SubmitButton = ({onValidated}) => {

            const submit = () => {
                onValidated({
                    EMAIL: this.state.emailAddress,
                    CASTALBUM: this.state.castAlbum,
                    ACCESS: this.state.access
                });
            };

            return (
                <StyledButtonStyled $inverse={this.props.$inverse} button onClick={submit}>
                    Sign up
                </StyledButtonStyled>
            )
        };

        return (
            <BgWrapper className={`${this.props.$inverse ? 'inverse' : ""}`}>
                <Container>
                    {this.props.$inverse && !this.props.$access &&
                        <h4>SIGN UP HERE FOR OFFICIAL LONDON CAST RECORDING NEWS</h4>
                    }
                    {!this.props.$inverse && this.props.$access &&
                        <h4>SIGN UP TO FIND OUT ABOUT FUTURE RELAXED PERFORMANCES</h4>
                    }
                    {!this.props.$inverse && !this.props.$access &&
                        <h4>Sign up now for news & announcements</h4>
                    }


                    <Row className="justify-content-center">
                        <Col xs="auto d-flex flex-wrap justify-content-center align-items-center">
                            <MailchimpSubscribe
                                url={url}
                                render={({subscribe, status, message}) => (
                                    <>
                                        <FormGroup className={`${this.props.$inverse ? 'inverse' : ""}`}>
                                            <StyledInput
                                                type="email"
                                                name="emailAddress"
                                                id="emailAddress"
                                                placeholder="ENTER YOUR EMAIL ADDRESS*"
                                                value={this.state.emailAddress}
                                                aria-label="ENTER YOUR EMAIL ADDRESS*"
                                                onChange={e => {
                                                    this.handleChange(e)
                                                }}
                                                className={`${this.props.$inverse ? 'inverse' : ""}`}
                                            />

                                            <SubmitButton onValidated={formData => subscribe(formData)}/>
                                        </FormGroup>
                                        {status === "error" && (
                                            <ErrorMessage
                                                dangerouslySetInnerHTML={{__html: this.formatMessage(message)}}/>
                                        )}
                                        {status === "success" &&
                                        <p className="text-center w-100 pt-2">Thanks for signing up for future
                                            information!</p>
                                        }
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                </Container>
            </BgWrapper>
        )
    }
}

export default SignUp

